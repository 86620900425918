<template>
    <maintenance-page
        page-title="Lease"
        page-icon="mdi-file-document-outline"
        collection-name="lease"
        done-redirect-url="/auth/admin/lease?isHistory=true"
        :buttons-left-items="buttons"
        :button-functions="{ propertyAreaRedirect }"
    />
</template>

<script>
import MaintenancePage from '../../../components/pages/Maintenance';

const buttons = [
    {
        id: 'propertyarea',
        clickFunctionName: 'propertyAreaRedirect',
        size: 'small',
        label: 'Areas'
    }]

export default {
    name: 'LeaseDetail',
    components: { MaintenancePage },
    data: () => ({
        buttons,
        leaseId: null,
    }),
    methods: {
        propertyAreaRedirect() {

            const doneRedirectUrl = `/auth/admin/lease/${this.leaseId}/leasearea`;

            this.$router.push(doneRedirectUrl);

        }
    },
    mounted () {
        this.leaseId = this.$route.params.id;
    }

};
</script>
